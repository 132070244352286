<template>
  <div class="home" style="text-align: center">
    <a href="http://web-pillar.com">
      <img alt="Vue logo" src="../assets/hero-imgbs.png" />
      <h1>Web-Pillar.com</h1></a
    >
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from "@/components/HelloWorld.vue";

export default {
  name: "Home",
  components: {
    // HelloWorld,
  },
};
</script>
